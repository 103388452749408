import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "../assets/styles/Results.module.css";
import Diff from "./Diff";

const InterestInventoryResults = () => {
  const params = useParams();
  const id = params.id;
  const user_id = params.user_id;
  const [results, setResults] = useState();
  useEffect(() => {
    if (results == undefined || results == null) getResults();
  });

  const getResults = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/get_ii_results/${user_id}/${id}/`,
        config
      );
      response.data[0].score = response.data[0].score.split(',')
      setResults(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.testDiv}>
      <h1 className={styles.testTitle}>Interest Inventory Test</h1>
      {results !== null && results !== undefined ? (
        <table className={styles.resultsTable} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Question</th>
              <th>Likeness</th>
            </tr>
          </thead>
          <tbody>
            {results
              ? results.options.map((option, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}.</td>
                    <td>{option}</td>
                    <td style={{ textAlign: "center" }}>
                      {results.score[index]}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default InterestInventoryResults;
