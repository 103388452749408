import React, { useEffect, useImperativeHandle,forwardRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styles from '../assets/styles/Letter.module.css'
const Letter = forwardRef((props,ref) => {
  const { listening, transcript, resetTranscript } = useSpeechRecognition();

const start = ()=>{
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
}
useImperativeHandle(ref, () => ({
  reset() {
    props.answer(props.index, transcript);
    resetTranscript();
  },
}));


  return (
    <div className={styles.letterContainer}>
      <h1 className={styles.letter}>
        {props.alphabet} 
      </h1>
      <div className={styles.btnContainer}>
        <button
          className={styles.speachbutton}
          onClick={() => {
            start();
          }}
        >
          START
        </button>
        <button
          className={styles.speachbutton}
          onClick={()=>{
            SpeechRecognition.stopListening();
            props.answer(props.index,transcript)
          }}
        >
          STOP
        </button>
        <button
          className={styles.speachbutton}
          disabled={transcript ? false : true}
          onClick={resetTranscript}
        >
          RESET
        </button>
      </div>
    </div>
  );
})

export default Letter