import React,{useEffect} from "react";
import styles from "../assets/styles/OurGoal.module.css";

const OurGoal = () => {
  useEffect(() => {
    document.title = "Peruzor | OUr Goal";
  }, []);
  return (
    <div className={styles.goalContainer}>
      <h1 className={styles.goalHeading}>OUR GOAL</h1>
      <p className={styles.goal}>
        Our Goal is to improve literacy. We plan to achieve this by focusing on
        improving Reading levels through personalized Reading programs. It
        begins with an assessment that identifies errors and provides a Reading
        Level. Then a tailored intervention is created based on the needs
      </p>
    </div>
  );
};

export default OurGoal;
