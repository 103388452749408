import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import styles from "../assets/styles/Login.module.css";
import whitelogo from "../assets/images/whitelogo.svg";
import AuthContext from "../context/AuthContext";
import { Circles } from "react-loading-icons";
import DjangoCSRFToken from "django-react-csrftoken";

const Login = () => {
  const { login } = useContext(AuthContext);
  const { loginError } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

    useEffect(() => {
      document.title = "Peruzor | Login";
    }, []);

  const onSubmit = (e) => {
    setLoading(true);
    login(e);
  };
  return (
    <div className={styles.container}>
      <div className={styles.loginbox}>
        <div className={styles.leftpanel}>
          <img src={whitelogo} alt="" width="300" />
          <p className={styles.tagline}>Where Transformation Meets Reading</p>
        </div>
        <div className={styles.rightpanel}>
          <h1>LOGIN</h1>
          <form
            className={styles.inputs}
            onSubmit={(e) => {
              onSubmit(e);
            }}
          >
            <DjangoCSRFToken />
            <input
              type="text"
              placeholder="Username"
              name="username"
              required
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              minLength="8"
              required
            />
            <Link to="/reset-password" className={styles.forgot}>
              Forgot Password/Username?
            </Link>
            <p className={styles.redtext}>{loginError}</p>

            <button type="submit" className={styles.login_button}>
              {loading && loginError === null ? (
                <Circles width="30px" height="30px" />
              ) : (
                "LOGIN"
              )}
            </button>
            <p className={styles.signup}>
              Don't have an account? <Link to="/signup">Signup!</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
