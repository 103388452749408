import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import AuthContext from "../context/AuthContext";
import styles from "../assets/styles/MultipleInventory.module.css";
import { Circles } from "react-loading-icons";

const InterestInventory = () => {
  const params = useParams();
  const user = params.user;
  const [number, setNumber] = useState(-1);
  const options = useRef([]);
  const ans = useRef(new Array(20).fill(-1));
  const res = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

    useEffect(() => {
      document.title = "Interest Inventory Test";
    }, []);

  const getOptions = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getintelliginventory/`,
        config
      );
      options.current = response.data;

      setNumber(number + 1);
    } catch (err) {
      console.log(err);
    }
  };

  function handleOnChange(index, answer) {
    ans.current[index] = answer;
  }

  const submitTest = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    const body = JSON.stringify({
      user: user,
      score:ans.current
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/interestinventory/results/`,
        body,
        config
      );
      if (response.status === 201) {
        setNumber(number + 1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.testContainer}>
      <h1 className={styles.testType}>INTEREST INVENTORY TEST</h1>
      <div className={styles.instructionbox}>
        <h2>INSTRUCTIONS</h2>
        <ul>
          <li>
            Please tell us more about the things you like by completing the
            Interest Inventory List.
          </li>
          <li>
            Read the activities below, then tick how much you like to do each. 4
            is like it a lot and 1 is not much.
          </li>
        </ul>
      </div>
      {number === -1 ? (
        <div>
          <button
            className={styles.largebutton}
            onClick={() => {
              getOptions();
            }}
          >
            START TEST
          </button>
        </div>
      ) : null}
      {number === 0 ? (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            submitTest();
          }}
        >
          <table
            className={styles.interestoptionBox}
            cellSpacing="0"
            cellPadding="0"
          >
            <tbody>
              {options.current.map((option, index) => (
                <tr className={styles.option} key = {index}>
                  <td style={{ fontWeight: "bold" }}>{index + 1}.</td>
                  <td>{option}</td>
                  <td>
                    <input
                      type="radio"
                      id={`a${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 4);
                      }}
                      required
                    />
                    <label htmlFor={`T${index}`}>4</label>
                  </td>
                  <td>
                    <input
                      type="radio"
                      id={`B${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 3);
                      }}
                    />
                    <label htmlFor={`F${index}`}>3</label>
                  </td>
                  <td>
                    <input
                      type="radio"
                      id={`C${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 2);
                      }}
                    />
                    <label htmlFor={`F${index}`}>2</label>
                  </td>
                  <td>
                    <input
                      type="radio"
                      id={`D${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 1);
                      }}
                    />
                    <label htmlFor={`F${index}`}>1</label>
                  </td>
                  <td>
                    <input
                      type="radio"
                      id={`N${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 0);
                      }}
                    />
                    <label htmlFor={`F${index}`}>Never</label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="submit" className={styles.largebutton}>
            {loading ? <Circles width="30px" height="30px" /> : "SUBMIT"}
          </button>
        </form>
      ) : null}
      {number > 0 ? (
        <div className={styles.resultBox}>
          <button
            className={styles.largebutton}
            onClick={() => {
              let test_type = '2'
               navigate(`/lettertest/${test_type}/`);
            }}
          >
            START POST TEST
          </button>
          <button
            className={styles.largebutton}
            onClick={() => {
              navigate(`/student_dashboard/${user}`);
            }}
          >
            DASHBOARD
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default InterestInventory;
