import React, {useEffect} from "react";
import { useState, useContext } from "react";
import styles from "../assets/styles/Signup.module.css";
import whitelogo from "../assets/images/whitelogo.svg";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import { Circles } from "react-loading-icons";

const Signup = () => {
  const { signup, signupError } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Peruzor | Sign up";
  }, []);

  const onSubmit = (e) => {
    setLoading(true);
    signup(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginbox}>
        <div className={styles.rightpanel}>
          <h1>SIGNUP</h1>
          <form
            onSubmit={(e) => {
              onSubmit(e);
            }}
            className={styles.inputs}
          >
            <input
              type="Email"
              placeholder="Enter Email"
              name="email"
              required
            />
            <input
              type="text"
              placeholder="Enter username"
              name="username"
              required
            />
            <input
              type="text"
              placeholder="Enter First Name"
              name="f_name"
              required
            />
            <input
              type="text"
              placeholder="Enter Last Name"
              name="l_name"
              required
            />
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              minLength="8"
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              name="re_password"
              minLength="8"
              required
            />
            <p>Password Should contain at least 8 characters.</p>
            <p className={styles.redtext}>
              {signupError !== null
                ? signupError.replaceAll("undefined", "")
                : null}
            </p>
            <button type="submit" className={styles.login_button}>
              {loading && signupError === null ? (
                <Circles width="30px" height="30px" />
              ) : (
                "SIGNUP"
              )}
            </button>
            <p className={styles.signup}>
              Already a Member? <Link to="/login">Login!</Link>
            </p>
          </form>
        </div>
        <div className={styles.leftpanel}>
          <img src={whitelogo} alt="" width="300" />
          <p className={styles.tagline}>Where Transformation Meets Reading</p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
