import React, { useState, useContext, Fragment } from "react";
import logo from "../assets/images/logo.svg";
import styles from "../assets/styles/Navbar.module.css";
import { useNavigate, Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import "react-router-dom";
import AuthContext from "../context/AuthContext";
const Navbar = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const {isStaff}  = useContext(AuthContext)
  let { user } = useContext(AuthContext);
  let { logout } = useContext(AuthContext);
  const guestLinks = () => (
    <Fragment>
      <button
        className={styles.navButtons}
        onClick={() => {
          setIsMobile(false);
          navigate("/signup");
        }}
      >
        REGISTER
      </button>
      <button
        className={styles.navButtons}
        onClick={() => {
           setIsMobile(false);
          navigate("/login");
        }}
      >
        LOGIN
      </button>
    </Fragment>
  );
  const authLinks = () => (
    <Fragment>
      <button
        className={styles.navButtons}
        onClick={() => {
          setIsMobile(false);
          if(!isStaff){
            
           navigate(`/student_dashboard/${user}`);
          }
          else{
            
             navigate(`/staff_dashboard/`);
          }
        }}
      >
        Dashboard
      </button>
      <button
        className={styles.navButtons}
        onClick={() => {
           setIsMobile(false);
          logout();
        }}
      >
        Logout
      </button>
    </Fragment>
  );

  return (
    <nav className={styles.Navbar}>
      <img src={logo} alt="" className={styles.logo} />
      <div className={styles.leftNav}>
        <FiMenu
          className={styles.hamburger}
          onClick={() => {
            setIsMobile(!isMobile);
          }}
        />
        <div className={!isMobile ? styles.innerleftnav : styles.toggled}>
          <Link to="/ourgoal" className={styles.ourGoal}>
            OUR GOAL
          </Link>
          {user ? authLinks() : guestLinks()}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
