import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Speaker from "../components/Speaker";
import styles from "../assets/styles/LetterTest.module.css";
import Letter from "../components/Letter";
import AuthContext from "../context/AuthContext";
const LetterTest = () => {
  const [number, setNumber] = useState(-1);
  const [score, setScore] = useState(0);
  const { user } = useContext(AuthContext);
  const letters = useRef([]);
  const ans = useRef([]);
  const childRef = useRef([]);
  const params = useParams();
  const test_type = params.test_type;
  const navigate = useNavigate();

    useEffect(() => {
      document.title = "Letters Test";
    }, []);

  const getLetters = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getletters/`,
        config
      );
      letters.current = response.data;
      setNumber(number + 1);
    } catch (err) {
      console.log(err);
    }
  };

  function getAnswer(index, answer) {
    if (answer.length > 0) {
      ans.current[index] = answer[answer.length - 1].toLowerCase();
    } else {
      ans.current[index] = "-1";
    }
  }

  function find_score() {
    let marks = 0;
    for (let i = 0; i <= letters.current.length-1; i++) {
      if (ans.current[i] === letters.current[i]) marks = marks + 1;
    }
    setScore(marks);
  }

  const postScore = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    const body = JSON.stringify({
      score: score,
      user: user,
      test_type: test_type,
      test_letters: letters.current.toString(),
      answer_letters: ans.current.toString(),
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/letters/score/`,
        body,
        config
      );
     if(response.status == 201){
       navigate(`/sentencetest/${test_type}`);
     }
    } catch (err) {
      console.log(err);
    }
  };

  function nextLevel() {
    postScore();
  }

  return (
    <div className={styles.testContainer}>
      <h1 className={styles.testType}>
        {test_type == "1" ? "PRE TEST" : "POST TEST"}
      </h1>
        <h2 className={styles.testTitle}>ALPHABET DISCRIMINATION</h2>
      <div className={styles.instructionbox}>
        <h2>Instructions</h2>
        <ul>
          <li>Click on START TEST button only once and wait.</li>
          <li>When test starts, Click on START button to and then speak</li>
          <li>First Click on STOP button than submit the test.</li>
          <li>After Submission, next letter will be shown.</li>
        </ul>
      </div>
      {number === -1 ? (
        <button
          onClick={() => {
            getLetters();
          }}
          className={styles.largebutton}
        >
          START TEST
        </button>
      ) : null}

      {letters.current.length !== 0 &&
      number < letters.current.length - 1 &&
      number > -1 ? (
        <div>
          <Letter
            index={number}
            alphabet={letters.current[number]}
            answer={getAnswer}
            ref={childRef}
          />
          <button
            className={styles.largebutton}
            onClick={() => {
              childRef.current.reset();
              setNumber(number + 1);
            }}
          >
            SUBMIT
          </button>
        </div>
      ) : null}
      {letters.current.length !== 0 && number === letters.current.length - 1 ? (
        <div>
          <Letter
            index={number}
            alphabet={letters.current[number]}
            answer={getAnswer}
            ref={childRef}
          />
          <button
            className={styles.largebutton}
            onClick={() => {
              childRef.current.reset();
              find_score();
              setNumber(number + 1);
            }}
          >
            SUBMIT TEST
          </button>
        </div>
      ) : null}
      {letters.current.length !== 0 && number === letters.current.length ? (
        <div>
          <h1 className={styles.score}>Letter Test Completed!</h1>
          <button
            onClick={() => {
              nextLevel();
            }}
            className={styles.largebutton}
          >
            NEXT
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default LetterTest;
