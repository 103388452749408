import React, { forwardRef, useImperativeHandle } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styles from "../assets/styles/Sentence.module.css";
import { Markup } from "interweave";




const Story = forwardRef((props, ref) => {
  const { listening, transcript, resetTranscript } = useSpeechRecognition();
  useImperativeHandle(ref, () => ({
    reset() {
      props.answer( transcript);
      SpeechRecognition.stopListening();
      resetTranscript();
    },
  }));

  const start = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  };
  return (
    <div className={styles.sentenceContainer}>
      {props.title !== undefined || props.title !== null?<h1 className={styles.sentence}>
         {props.title}
      </h1>:null}
        <Markup content = {props.text} />
      <div className={styles.btnContainer}>
        <button
          className={styles.speachbutton}
          onClick={() => {
            start();
          }}
        >
          START
        </button>
        <button
          className={styles.speachbutton}
          onClick={() => {
            SpeechRecognition.stopListening();
            props.answer(transcript);
          }}
        >
          STOP
        </button>
        <button
          className={styles.speachbutton}
          disabled={transcript ? false : true}
          onClick={resetTranscript}
        >
          RESET
        </button>
      </div>
    </div>
  );
});

export default Story;
