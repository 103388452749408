import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import AuthContext from "../context/AuthContext";
import styles from '../assets/styles/MultipleInventory.module.css'
import { Circles } from "react-loading-icons";

const MultipleInventory = () => {
    const params = useParams()
    const user = params.user 
    const [number, setNumber] = useState(-1)
    const options = useRef([])
    const ans = useRef(new Array(30).fill(-1))
    const res = useRef()
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
      document.title = "Multiple Intelligence Inventory Test";
    }, []);

    const getOptions = async ()=>{
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${localStorage.getItem("access")}`,
            "x-csrftoken": Cookies.get("csrftoken"),
          },
        };
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/getmultipleinventory/`,
            config
          );
          options.current = response.data;
          setNumber(number + 1);
        } catch (err) {
          console.log(err);
        }
    }

    function handleOnChange(index,answer) {
        ans.current[index] = answer
    }

    const submitTest = async ()=>{
      setLoading(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${localStorage.getItem("access")}`,
            "x-csrftoken": Cookies.get("csrftoken"),
          },
        };
        const body = JSON.stringify({
          user: user,
          options: options.current,
          answers:ans.current
        });
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/multipleinventory/results/`,
            body,
            config
          );
          if(response.status === 201){
            res.current = response.data
            setNumber(number + 1)
          }
          
        } catch (err) {
          console.log(err);
        }
    }

  function computeScore(score) {
    let s = 0;
    for (let i = 0; i < score.length; i++) {
      if (parseInt(score[i]) === 1) {
        s = s + 1;
      }
    }
    return s;
  }


  return (
    <div className={styles.testContainer}>
      <h1 className={styles.testType}>MULTIPLE INTELLIGENCE INVENTORY TEST</h1>
      <div className={styles.instructionbox}>
        <h2>INSTRUCTIONS</h2>
        <ul>
          <li>
            We are interested in knowing more about you. Please tell us about
            the things you like by completing the Multiple intelligence and the
            Interest Inventory Lists.
          </li>
          <li>
            Where does your true intelligence lie? This quiz will tell you where
            you stand and what to do about it. Read each statement. If it
            expresses some characteristic of yours and sounds true for the most
            part, jot down a "T." If it doesn't, mark an "F." If the statement
            is sometimes true, sometimes false, leave it blank.
          </li>
        </ul>
      </div>
      {number === -1 ? (
        <div>
          <button
            className={styles.largebutton}
            onClick={() => {
              getOptions();
            }}
          >
            START TEST
          </button>
        </div>
      ) : null}
      {number === 0 ? (
        <div>
          <table className={styles.optionBox} cellSpacing="0" cellPadding="0">
            <tbody>
              {options.current.map((option, index) => (
                <tr className={styles.option}>
                  <td style={{ fontWeight: "bold" }}>{index + 1}.</td>
                  <td>{option}</td>
                  <td>
                    <input
                      type="radio"
                      id={`T${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 1);
                      }}
                    />
                    <label htmlFor={`T${index}`}>T</label>
                  </td>
                  <td>
                    <input
                      type="radio"
                      id={`F${index}`}
                      name={`index${index}`}
                      onChange={() => {
                        handleOnChange(index, 0);
                      }}
                    />
                    <label htmlFor={`F${index}`}>F</label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className={styles.largebutton}
            onClick={() => {
              submitTest();
            }}
          >
            {loading ? <Circles width="30px" height="30px" /> : "SUBMIT"}
          </button>
        </div>
      ) : null}
      {number > 0 ? (
        <div className={styles.resultBox}>
          <h1>Multiple Intelligence Test Completed!</h1>
          <button
            className={styles.largebutton}
            onClick={() => {
              navigate(`/interestinventory/${user}`);
            }}
          >
            NEXT
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default MultipleInventory;
