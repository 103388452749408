import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "../assets/styles/SentenceTest.module.css";
import Cookies from "js-cookie";
import Sentence from "../components/Sentence";

export const WordsTest = () => {
  const params = useParams();
  const test_type = params.test_type;
  const level = useRef(params.level);
  const user = parseInt(params.user);
  const words = useRef([]);
  const [number, setNumber] = useState(-1);
  const ans = useRef([]);
  const childRef = useRef([]);
  const [score,setScore]  = useState(0)
  const navigate = useNavigate()
  let scoreTracker = useRef([-1, -1, -1, -1, -1, -1, -1, - 1]);

  useEffect(() => {
    document.title = "Words Test";
  }, []);

  const getWords = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getwords/${level.current[0]}/`,
        config
      );
      words.current = response.data;
      setNumber(number + 1);
    } catch (err) {
      console.log(err);
    }
  };


  function getAnswer(index, text) {
   if(text === null || text === undefined) {
    ans.current[index] = " ";
   }
   else{
    let word = text.split(" ");
    ans.current[index] = word[word.length - 1];
    if(ans.current[index].toLowerCase()===words.current[index].toLowerCase()) setScore(score+1)   }
  }
  const postResults = async () => {
    scoreTracker.current[level] = score;
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    const body = JSON.stringify({
      words: words.current.toString(),
      spoken_words: ans.current.toString(),
      user: user,
      level: level.current[0],
      test_type: test_type,
      time: dateTime,
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/words/results/`,
        body,
        config
      );
    } catch (err) {
      console.log(err);
    }
  };


  function nextLevel(){
    let percent = (score * 100) / words.current.length;
    if(percent >70 && level.current !==7){
      navigate(`/wordstest/${test_type}/${level+1}/${user}/`);
      window.location.reload(false);
    }
    else{
      if(level.current < 5){
        navigate(`/storyTest/${test_type}/${level - 1}/${user}/`);
      }
      else{
        navigate(`/storyTest/${test_type}/5/${user}/`);
      }
    }
  }
  return (
    <div className={styles.testContainer}>
      <h1 className={styles.testType}>
        {test_type === "1" ? "PRE TEST" : "POST TEST"}
      </h1>
      <h2 className={styles.testTitle}>WORDS TEST</h2>
      <div className={styles.instructionbox}>
        <h2>Instructions</h2>
        <ul>
          <li>Click on START TEST button only once and wait.</li>
          <li>When test starts, Click on START button to and then speak</li>
          <li>First Click on STOP button then submit the test.</li>
          <li>After Submission, next word will be shown.</li>
        </ul>
      </div>
      {number === -1 ? (
        <div>
          <button
            className={styles.largebutton}
            onClick={() => {
              getWords();
            }}
          >
            START TEST
          </button>
        </div>
      ) : null}
      {number >= 0 &&
      number < words.current.length - 1 &&
      words.current.length !== 0 ? (
        <div>
          <div>
            <Sentence
              index={number}
              text={words.current[number]}
              answer={getAnswer}
              ref={childRef}
            />
            <button
              className={styles.largebutton}
              onClick={() => {
                childRef.current.reset();
                setNumber(number + 1);
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      ) : null}
      {number === words.current.length - 1 && words.current.length !== 0 ? (
        <div>
          <Sentence
            index={number}
            text={words.current[number]}
            answer={getAnswer}
            ref={childRef}
          />
          <button
            className={styles.largebutton}
            onClick={() => {
              childRef.current.reset();
              setNumber(number + 1);
              postResults();
            }}
          >
            SUBMIT TEST
          </button>
        </div>
      ) : null}
      {number === words.current.length ? (
        <div>
          <h1 className={styles.score}>Words Test Completed!</h1>
          <button
            className={styles.largebutton}
            onClick={() => {
              nextLevel();
            }}
          >
            NEXT
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default WordsTest;
