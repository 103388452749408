import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "../assets/styles/Results.module.css";
import Diff from "./Diff";

const MultipleIntelligenceResults = () => {
  const params = useParams();
  const id = params.id;
  const user_id = params.user_id;
  const [results, setResults] = useState();
  const score = useRef(0);
  useEffect(() => {
    if (results == undefined || results == null) getResults();
  });

  const getResults = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/get_mi_results/${user_id}/${id}/`,
        config
      );
      setResults(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };


  function calculateScore(score){
    let s = 0
    for(let i = 0;i<score.length;i++){
        if(parseInt(score[i]) === 1){s = s+1}
    }
    return s;
  }

  return (
    <div className={styles.testDiv}>
      <h1 className={styles.testTitle}>Multiple Intellignece Test</h1>
      {results !== null && results !== undefined ? (
        <table className={styles.resultsTable} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Category</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }} >1.</td>
              <td style={{ textAlign: "center" }} >Linguistic</td>
              <td style={{ textAlign: "center" }} >{calculateScore(results["linguistic"].split(","))}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} >2.</td>
              <td style={{ textAlign: "center" }} >Logical Mathematical</td>
              <td style={{ textAlign: "center" }} >
                {calculateScore(results["logical_mathematical"].split(","))}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} >3.</td>
              <td style={{ textAlign: "center" }} >Musical</td>
              <td style={{ textAlign: "center" }} >{calculateScore(results["musical"].split(","))}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} >4.</td>
              <td style={{ textAlign: "center" }} >Spatial</td>
              <td style={{ textAlign: "center" }} >{calculateScore(results["spatial"].split(","))}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} >5.</td>
              <td style={{ textAlign: "center" }} >Bodily</td>
              <td style={{ textAlign: "center" }} >{calculateScore(results["bodily"].split(","))}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} >6.</td>
              <td style={{ textAlign: "center" }} >Inter Personal</td>
              <td style={{ textAlign: "center" }} >{calculateScore(results["inter_personal"].split(","))}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} >7.</td>
              <td style={{ textAlign: "center" }} >Intra Personal</td>
              <td style={{ textAlign: "center" }} >{calculateScore(results["intra_personal"].split(","))}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default MultipleIntelligenceResults;
