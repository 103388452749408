import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "../assets/styles/Results.module.css";
import Diff from "./Diff";

const StoryResults = () => {
  const params = useParams();
  const id = params.id;
  const user_id = params.user_id;
  const [results, setResults] = useState();

  useEffect(() => {
    if (results == undefined || results == null) getResults();
  });

  const getResults = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/get_story_results/${user_id}/${id}/`,
        config
      );
      response.data[0].questions_list =
        response.data[0].questions_list.split(",");
      response.data[0].answers_list = response.data[0].answers_list.split(",");
      if (response.data[0].story_score >= 80)
        response.data[0].remarks = "Independent";
      else if (
        (response.data[0].story_score >= 60 && response.data[0].story_score, 80)
      )
        response.data[0].remarks = "Instructional";
      else response.data[0].remarks = "Frustrational";

      let answers_sum =
        response.data[0].answers_score.reduce(function (a, b) {
          return a + b;
        }, 0) / response.data[0].answers_score.length;
      if (answers_sum > 80) response.data[0].answers_remarks = "Independent";
      else if (answers_sum < 80 && answers_sum > 40)
        response.data[0].answers_remarks = "Instructional";
      else response.data[0].answers_remarks = "Frustrational";

      setResults(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.testDiv}>
      <h1 className={styles.testTitle}>Story Test</h1>
      <h2 className={styles.testTitle}>
        Story Score: {results ? parseInt(results.story_score) : null}/100
      </h2>
      <h3 className={styles.testTitle}>
        Remarks: {results ? results.remarks : null}
      </h3>
      <div className={styles.correct_story}>
        {results ? (
          <div>
            <Diff
              string1={results.story.toLowerCase().replace("\r\n", " ")}
              string2={results.spoken_story.toLowerCase()}
              mode={"words"}
            ></Diff>
          </div>
        ) : null}
      </div>
      <h1 className={styles.testTitle}>Questions</h1>
      <h3 className={styles.testTitle}>
        Remarks: {results ? results.answers_remarks : null}
      </h3>
      {results !== null && results !== undefined ? (
        <table className={styles.resultsTable} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Question</th>
              <th>Answer</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {results.answers_list.map((value, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td>{results.questions_list[index]}</td>
                <td>
                  {value === "-1"
                    ? "null"
                    : value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")}
                </td>
                <td>{parseInt(results.answers_score[index])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default StoryResults;
