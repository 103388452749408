import React, { useEffect, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import styles from "../assets/styles/ResetPassword.module.css";
import axios from "axios";
import AuthContext from "../context/AuthContext";

const Activate = () => {
  const { activation, activationError } = useContext(AuthContext);
  const params = useParams();
  const uid = params.uid;
  const token = params.token;
  useEffect(() =>{
    document.title = "Account Activation"
  },[])

  return (
    <div className={styles.resetContainer}>
      <h1>Verify Account</h1>
      <p>Please click on the button to verify your Email.</p>
      <button onClick={activation(uid, token)} className = {styles.largebutton}>Activate</button>
      <p style={{ color: "red" }}>{activationError}</p>
    </div>
  );
};

export default Activate;
