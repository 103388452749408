import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import OurGoal from "./containers/OurGoal";
import Activate from "./containers/Activate";
import Intro from "./containers/Intro";
import LetterTest from './containers/LetterTest'
import Sentences from './containers/Sentences'
import WordsTest from './containers/WordsTest'
import StoryTest from './containers/StoryTest'
import ResetPassword from "./containers/ResetPassword";
import ResetPasswordConfirm from "./containers/ResetPasswordConfirm";
import StaffDashboard from "./containers/StaffDashboard";
import StudentDashboard from "./containers/StudentDashboard";
import MultipleInventory from "./containers/MultipleInventory";
import InterestInventory from "./containers/InterestInventory";
import StudentAnalytics  from "./components/StudentAnalytics";
import SentenceResults from "./components/SentenceResults";
import LetterResults from "./components/LetterResults";
import StoryResults from "./components/StoryResults";
import WordsResults from "./components/WordsResults";
import MultipleIntelligenceResults from "./components/MultipleIntelligenceResults";
import InterestInventoryResults from "./components/InterestInventoryResults";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./utils/PrivateRoutes.js";
import StaffRoutes from "./utils/StaffRoutes.js";
import { AuthProvider } from "./context/AuthContext";
import icon from "./assets/images/favicon.ico"
import {useEffect} from 'react'
function App() {

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route element={<PrivateRoutes />}>
            <Route exact path="/" element={<Home />} />
            <Route path="/intro/:user_id" element={<Intro />} />

            <Route
              path="/student_dashboard/:user_id"
              element={<StudentDashboard />}
            />
            <Route path="/lettertest/:test_type" element={<LetterTest />} />
            <Route path="/sentencetest/:test_type" element={<Sentences />} />
            <Route
              path="/multipleinventorytest/:user"
              element={<MultipleInventory />}
            />

            <Route
              path="/interestinventory/:user"
              element={<InterestInventory />}
            />
            <Route
              path="/storyTest/:test_type/:level/:user/"
              element={<StoryTest />}
            />
            <Route
              path="/wordstest/:test_type/:level/:user/"
              element={<WordsTest />}
            />
            <Route element={<StaffRoutes />}>
              <Route path="/staff_dashboard/" element={<StaffDashboard />} />
              <Route
                path="/student_analytics/:id/"
                element={<StudentAnalytics />}
              />
              <Route
                path="/SentenceResults/:user_id/:id"
                element={<SentenceResults />}
              />
              <Route
                path="/LetterResults/:user_id/:id"
                element={<LetterResults />}
              />
              <Route
                path="/WordsResults/:user_id/:id"
                element={<WordsResults />}
              />
              <Route
                path="/StoryResults/:user_id/:id"
                element={<StoryResults />}
              />
              <Route
                path="/miResults/:user_id/:id"
                element={<MultipleIntelligenceResults />}
              />
              <Route
                path="/iiResults/:user_id/:id"
                element={<InterestInventoryResults />}
              />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/ourgoal" element={<OurGoal />} />
          <Route path="/activate/:uid/:token" element={<Activate />} />
          <Route
            path="/reset/confirm/:uid/:token"
            element={<ResetPasswordConfirm />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
