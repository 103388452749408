import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import styles from '../assets/styles/StaffDashboard.module.css'



const StaffDashboard = () => {
  const navigate = useNavigate()
  const [usersList, setUsersList]  = useState()
  const [searchValue, setSearchValue] = useState("")

  useEffect(()=>{
    document.title = "Staff Dashboard"
    if(usersList === null || usersList === undefined) getUsers()
  },[])


  const getUsers = async ()=>{
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL+"/getuserslist/",
        config
      );
      setUsersList(response.data)
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <h1 className={styles.centerHeading}>STAFF DASHBOARD</h1>
      <input
        type="text"
        placeholder="Search by Name"
        className={styles.search}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <div className={styles.cardsDiv}>
        {usersList
          ? usersList
              .filter((user) => {
                if (searchValue == "") {
                  return user;
                } else if (
                  (
                    user.f_name.toLowerCase() +
                    " " +
                    user.l_name.toLowerCase()
                  ).includes(searchValue.toLowerCase())
                ) {
                  return user;
                }
              })
              .map((user, index) => (
                <div
                  key={index}
                  className={styles.card}
                  onClick={() => {
                    navigate(`/student_analytics/${user.id}/`);
                  }}
                >
                  <h1 className={styles.name}>
                    {user.f_name + " " + user.l_name}
                  </h1>
                  <p className={styles.username}>
                    <span className={styles.bold}>Username: </span>
                    {user.username}
                  </p>
                  <p className={styles.email}>
                    <span className={styles.bold}>Email: </span>
                    {user.email}
                  </p>
                  <p className={styles.date_joined}>
                    <span className={styles.bold}>Date Joined: </span>
                    {new Date(user.date_joined).toString()}
                  </p>
                  <p className={styles.last_login}>
                    <span className={styles.bold}>Last Login: </span>
                    {new Date(user.last_login).toString()}
                  </p>
                </div>
              ))
          : null}
      </div>
    </div>
  );
}

export default StaffDashboard