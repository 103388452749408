import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

  let [access, setAccess] = useState(localStorage.getItem("access"));

  let [refresh, setRefresh] = useState(localStorage.getItem("refresh"));
  let [user, setUser] = useState(() =>{
    if(localStorage.getItem("access") !== null){
      const user_id = jwt_decode(localStorage.getItem("access")).user_id;
      return user_id;
    }
    else {return null}
  }
  );
  // let [user, setUser] = useState(null);
  let [loginError, setLoginError] = useState(null);
  let [signupError, setSignupError] = useState(null);
  let [activationError, setActivationError] = useState(null);
  let [isStaff, setIsStaff] = useState()
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  let login = async (e) => {
    setLoginError(null);
    e.preventDefault();
    const body = JSON.stringify({
      username: e.target.username.value,
      password: e.target.password.value,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        // "X-CSRFToken": Cookies.get("csrftoken"),
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/create/`,
        body,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("refresh", response.data.refresh);
        localStorage.setItem("access", response.data.access);
        setAccess(response.data.access);
        setRefresh(response.data.refresh);
        setUser(jwt_decode(response.data.access).user_id);
        setLoading(true);
         loadUser(jwt_decode(localStorage.getItem("access")).user_id);
        // navigate("/");
      } 
      
    } catch (err) {
      setLoginError("Email is not verified or Username or Password is wrong!");
      setUser(null);
    }
   
  };

  const loadUser = async (user_id)=>{
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/users/me/`,
          config
        );
        staff_validation(user_id);
      } catch (err) {
        console.log(err)
      }
    } 
  

  let signup = async (e) => {
    setLoginError(null);
    e.preventDefault();
    const body = JSON.stringify({
      username: e.target.username.value,
      email: e.target.email.value,
      f_name: e.target.f_name.value,
      l_name: e.target.l_name.value,
      password: e.target.password.value,
      re_password: e.target.re_password.value,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (e.target.password.value === e.target.re_password.value) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/users/`,
          body,
          config
        );
        if (response.status === 201) {
          navigate("/");
        }
      } catch (err) {
        setSignupError(
          err.response.data.email + " \n" + err.response.data.username
        );
      }
    } else {
      setSignupError("Passwords Don't Match.");
    }
  };

  const logout = () => {
    setAccess(null);
    setRefresh(null)
    setUser(null);
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    setLoginError(null);
    navigate("/");
  };

  let updateToken = async () => {
    if(refresh !== null){
          let response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/jwt/refresh`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                refresh: localStorage.getItem("refresh"),
              }),
            }
          );

    let data = await response.json();

    if (response.status === 200) {
      setUser(jwt_decode(data.access).user_id);
      localStorage.setItem("access", data.access);
      // localStorage.setItem("refresh", data.refresh);
      setAccess(data.access);
      setRefresh(data.refresh);
    } else {
      logout();
    }

    if (loading) {
      setLoading(false);
    }
    }

  };

  const activation = (uid, token) => async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": document.cookie.csrfToken,
      },
    };

    const body = JSON.stringify({ uid, token });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/activation/`,
        body,
        config
      );
      navigate("/login");
    } catch (err) {
      setActivationError(err.response.data.uid + " " + err.response.data.token);
    }
  };

  const staff_validation = async (user_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/staff-validation/${user_id}/`,
        config
      );

      if (response.data.is_staff) {
        setIsStaff(true)
        navigate('/staff_dashboard')
      } else {
        setIsStaff(false)
        intro_page(user_id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const intro_page = async (user_id)=>{

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/intro-validation/${user_id}/`,
        config
      );

      if(response.data.found){
        navigate(`/student_dashboard/${user_id}`)
      }
      else{
        navigate(`/intro/${user_id}`)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    let fourMinutes = 1000 * 60 * 400;

    let interval = setInterval(() => {
      if (access) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [access, loading]);

  let contextData = {
    user: user,
    loginError: loginError,
    signupError: signupError,
    activationError: activationError,
    login: login,
    logout: logout,
    signup: signup,
    activation: activation,
    isStaff: isStaff,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
