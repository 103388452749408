import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "../assets/styles/Results.module.css";
import Diff from "./Diff";

const LetterResults = () => {
  const params = useParams();
  const id = params.id;
  const user_id = params.user_id;
  const [results, setResults] = useState();

  useEffect(() => {
    if (results == undefined || results == null) getResults();
  });

  const getResults = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/get_letter_results/${user_id}/${id}/`,
        config
      );
      response.data[0].test_letters = response.data[0].test_letters.split(',')
      response.data[0].answer_letters = response.data[0].answer_letters.split(',')
      setResults(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.testDiv}>
      <h1 className={styles.testTitle}>Sentence Test</h1>
      <h2 className={styles.testTitle}>
        Score: {results ? results.score : null}/26
      </h2>
      {results !== null && results !== undefined ? (
        <table className={styles.resultsTable} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Acutal Letter</th>
              <th>Spoken Letter</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {results.answer_letters.map((value, index) => (
              <tr
                key={index}
                style={
                  value !== results.test_letters[index]
                    ? {
                        backgroundColor: "rgba(249, 97, 77, 0.671)",
                        textAlign: "center",
                      }
                    : { textAlign: "center" }
                }
              >
                <td>{index + 1}.</td>
                <td>{results.test_letters[index].toUpperCase()}</td>
                <td>{value === '-1'?"null":value.toUpperCase()}</td>
                <td>{value === results.test_letters[index] ? "1" : "0"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default LetterResults;
