import React, { useState, useEffect } from "react";
import { useNavigate ,useParams} from "react-router-dom";
import axios from 'axios'
import styles from '../assets/styles/ResetPassword.module.css';

const ResetPasswordConfirm = () => {
  const [resetError, setResetError] = useState()
    const params = useParams();
    const navigate = useNavigate()

    useEffect(() => {
      document.title = "Reset Password Confirm";
    }, []);

    const onSubmit = async (e) => {
      e.preventDefault();      
      const uid = params.uid;
      const token = params.token;
    const new_password = e.target.new_password.value;
    const re_new_password = e.target.re_new_password.value;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({uid,token,new_password, re_new_password });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
        body,
        config
      );
      if(response.status !== 400){
      navigate("/login");}
    } catch (err) {
      console.log(err)
      setResetError(err.response.data[0]);
    }
    
    };

  return (
    <div className={styles.resetContainer}>
      <h1>Reset Password</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="">
          Please enter your new password.
        </label>
        <input
          type="password"
          placeholder="New Password"
          name="new_password"
          minLength="8"
          required
        />
        <input
          type="password"
          placeholder="Re-enter New Password"
          name="re_new_password"
          minLength="8"
          required
        />
        <p>{resetError != undefined ? resetError:null}</p>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ResetPasswordConfirm;
