import React, {useState, useEffect, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from '../assets/styles/StudentAnalytics.module.css'



const StudentAnalytics = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id = parseInt(params.id);
  const profileTable = useRef()
  const [usersList, setUsersList]  = useState()
  const [searchValue, setSearchValue] = useState("")
  const [testsList, setTestsLists] = useState()

  useEffect(()=>{
    document.title = "Staff Analytics"
    if(usersList == null || usersList === undefined) getUsers()
    if (testsList === undefined || testsList == null) getTests();
  },[])


  const getUsers = async ()=>{
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL+"/getuserslist/",
        config
      );
      setUsersList(response.data)
    } catch (err) {
      console.log(err);
    }
  }


  const getTests = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${localStorage.getItem("access")}`,
        "x-csrftoken": Cookies.get("csrftoken"),
      },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/gettestsresults/${id}/`,
        config
      );
      setTestsLists(response.data);
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div className={styles.profileDiv}>
      <h1 className={styles.profile}>PROFILE</h1>
      {usersList
        ? usersList
            .filter((user) => {
              if (user.id == id) {
                return user;
              }
            })
            .map((user, index) => (
              <table
                cellSpacing="0"
                cellPadding="0"
                className={styles.profileTable}
                key={index}
              >
                <tbody>
                  <tr>
                    <td className={styles.bold}>Name:</td>
                    <td>{(user.f_name + " " + user.l_name).toUpperCase()}</td>
                  </tr>
                  <tr>
                    <td className={styles.bold}>Username:</td>
                    <td>{user.username}</td>
                  </tr>
                  <tr>
                    <td className={styles.bold}>Email:</td>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <td className={styles.bold}>Date Joined:</td>
                    <td>{new Date(user.date_joined).toString()}</td>
                  </tr>
                  <tr>
                    <td className={styles.bold}>Last Login:</td>
                    <td>{new Date(user.last_login).toString()}</td>
                  </tr>
                </tbody>
              </table>
            ))
        : null}
      <div className={styles.testDiv}>
        <div>
          <h1 className={styles.testHeading}>Sentences Tests</h1>
          <table cellSpacing="0" cellPadding="0" className={styles.testList}>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Test Date</th>
                <th>Time</th>
                <th>Test Type</th>
              </tr>
            </thead>
            {testsList && testsList.sentences_results !== null
              ? testsList.sentences_results.map((test, index) => (
                  <tbody key={index}>
                    <tr
                      onClick={() => {
                        navigate(`/SentenceResults/${id}/${test.id}`);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(test.time).getDate()}/
                        {new Date(test.time).getMonth()}/
                        {new Date(test.time).getFullYear()}
                      </td>
                      <td>
                        {new Date(test.time).getHours()}:
                        {new Date(test.time).getMinutes()}:
                        {new Date(test.time).getSeconds()}
                      </td>
                      <td>{test.test_type == 1 ? "Pre Test" : "Post Test"}</td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </div>
        <div>
          <h1 className={styles.testHeading}>Letter Tests</h1>
          <table cellSpacing="0" cellPadding="0" className={styles.testList}>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Test Date</th>
                <th>Time</th>
                <th>Test Type</th>
              </tr>
            </thead>
            {testsList && testsList.letter_results !== null
              ? testsList.letter_results.map((test, index) => (
                  <tbody key={index}>
                    <tr
                      onClick={() => {
                        navigate(`/LetterResults/${id}/${test.id}`);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(test.time).getDate()}/
                        {new Date(test.time).getMonth()}/
                        {new Date(test.time).getFullYear()}
                      </td>
                      <td>
                        {new Date(test.time).getHours()}:
                        {new Date(test.time).getMinutes()}:
                        {new Date(test.time).getSeconds()}
                      </td>
                      <td>{test.test_type == 1 ? "Pre Test" : "Post Test"}</td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </div>
        <div>
          <h1 className={styles.testHeading}>Words Tests</h1>
          <table cellSpacing="0" cellPadding="0" className={styles.testList}>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Test Date</th>
                <th>Time</th>
                <th>Test Type</th>
              </tr>
            </thead>
            {testsList && testsList.words_results !== null
              ? testsList.words_results.map((test, index) => (
                  <tbody key={index}>
                    <tr
                      onClick={() => {
                        navigate(`/WordsResults/${id}/${test.id}`);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(test.time).getDate()}/
                        {new Date(test.time).getMonth()}/
                        {new Date(test.time).getFullYear()}
                      </td>
                      <td>
                        {new Date(test.time).getHours()}:
                        {new Date(test.time).getMinutes()}:
                        {new Date(test.time).getSeconds()}
                      </td>
                      <td>{test.test_type == 1 ? "Pre Test" : "Post Test"}</td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </div>
        <div>
          <h1 className={styles.testHeading}>Story List</h1>
          <table cellSpacing="0" cellPadding="0" className={styles.testList}>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Test Date</th>
                <th>Time</th>
                <th>Test Type</th>
              </tr>
            </thead>
            {testsList && testsList.story_results !== null
              ? testsList.story_results.map((test, index) => (
                  <tbody key={index}>
                    <tr
                      onClick={() => {
                        navigate(`/StoryResults/${id}/${test.id}`);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(test.time).getDate()}/
                        {new Date(test.time).getMonth()}/
                        {new Date(test.time).getFullYear()}
                      </td>
                      <td>
                        {new Date(test.time).getHours()}:
                        {new Date(test.time).getMinutes()}:
                        {new Date(test.time).getSeconds()}
                      </td>
                      <td>{test.test_type == 1 ? "Pre Test" : "Post Test"}</td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </div>

        <div>
          <h1 className={styles.testHeading}>Multiple Intelligence Tests</h1>
          <table cellSpacing="0" cellPadding="0" className={styles.testList}>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Test Date</th>
                <th>Time</th>
              </tr>
            </thead>
            {testsList && testsList.mi_results !== null
              ? testsList.mi_results.map((test, index) => (
                  <tbody key={index}>
                    <tr
                      onClick={() => {
                        navigate(`/miResults/${id}/${test.id}`);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(test.time).getDate()}/
                        {new Date(test.time).getMonth()}/
                        {new Date(test.time).getFullYear()}
                      </td>
                      <td>
                        {new Date(test.time).getHours()}:
                        {new Date(test.time).getMinutes()}:
                        {new Date(test.time).getSeconds()}
                      </td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </div>
        <div>
          <h1 className={styles.testHeading}>Interests Inventory Tests</h1>
          <table cellSpacing="0" cellPadding="0" className={styles.testList}>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Test Date</th>
                <th>Time</th>
              </tr>
            </thead>
            {testsList && testsList.ii_results !== null
              ? testsList.ii_results.map((test, index) => (
                  <tbody key={index}>
                    <tr
                      onClick={() => {
                        navigate(`/iiResults/${id}/${test.id}`);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(test.time).getDate()}/
                        {new Date(test.time).getMonth()}/
                        {new Date(test.time).getFullYear()}
                      </td>
                      <td>
                        {new Date(test.time).getHours()}:
                        {new Date(test.time).getMinutes()}:
                        {new Date(test.time).getSeconds()}
                      </td>
                    </tr>
                  </tbody>
                ))
              : null}
          </table>
        </div>
      </div>
    </div>
  );
}
export default StudentAnalytics