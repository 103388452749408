import React, {  forwardRef, useImperativeHandle } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import styles from "../assets/styles/Sentence.module.css";
const Sentence = forwardRef((props,ref) => {
  const { listening, transcript, resetTranscript } = useSpeechRecognition();
useImperativeHandle(ref, () => ({
  reset() {
    props.answer(props.index, transcript);
    SpeechRecognition.stopListening();
    resetTranscript();
  },
}));


  const start = () => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  };
  return (
    <div className={styles.sentenceContainer}>
      <h1 className={styles.sentence}>
        {props.text} 
      </h1>
      <div className={styles.btnContainer}>
        <button
          className={styles.speachbutton}
          onClick={() => {
            start();
          }}
        >
          START
        </button>
        <button
          className={styles.speachbutton}
          onClick={() => {
            SpeechRecognition.stopListening();
            props.answer(props.index, transcript);
          }}
        >
          STOP
        </button>
        <button
          className={styles.speachbutton}
          disabled={transcript ? false : true}
          onClick={resetTranscript}
        >
          RESET
        </button>
      </div>
    </div>
  );
});

export default Sentence;
