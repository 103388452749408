import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../assets/styles/ResetPassword.module.css";
import axios from 'axios'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [activationError, setActivationError] = useState()

  useEffect(() => {
    document.title = "Reset Password";
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
        body,
        config
      );
      if(response.status !== 400){
      navigate("/login");}
    } catch (err) {
      console.log(err)
      setActivationError(err.response.data);
    }
    
  };
  return (
    <div className={styles.resetContainer}>
      <h1>Reset Password</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="">
          Forgot your password or username? Please Enter you Email to get Username and Password reset
          Link.
        </label>
        <input
          type="email"
          name="email"
          placeholder="Enter a valid Email address"
          required
        />
        <p className={styles.error}>{activationError !== undefined ?activationError:null}</p>
        <button type="submit" >SUBMIT</button>
      </form>
    </div>
  );
};

export default ResetPassword;
