import React,{useEffect, useState,useRef} from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import styles from '../assets/styles/Results.module.css'
import Diff from './Diff'

const SentenceResults = () => {
    const params = useParams()
    const id = params.id
    const user_id = params.user_id
    const [results,setResults] = useState()

    useEffect(()=>{
            if(results==undefined || results == null) getResults();
    })

    const getResults = async ()=>{
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${localStorage.getItem("access")}`,
            "x-csrftoken": Cookies.get("csrftoken"),
          },
        };
        try {
          const response = await axios.get(
            process.env.REACT_APP_API_URL + `/get_sentence_results/${user_id}/${id}/`,
            config
          );
          setResults(response.data);
        } catch (err) {
          console.log(err);
        }
    }

  return (
    <div className={styles.testDiv}>
      <h1 className={styles.testTitle}>Sentence Test</h1>
      {results !== null && results !== undefined ? (
        <table className={styles.resultsTable} cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Acutal Letter</th>
              <th>Spoken Letter</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {results.map((value, index) => (
              <tr
                key={index}
                style={
                  parseInt(value[2]) < 90
                    ? { backgroundColor: "rgba(249, 97, 77, 0.671)" }
                    : {}
                }
              >
                <td>{index + 1}.</td>
                <td>{value[0]}</td>
                <td>
                  {value[1] !== "" ? (
                    <Diff
                      string1={value[0].toLowerCase()}
                      string2={value[1]}
                      mode={"words"}
                    />
                  ) : (
                    "null"
                  )}
                </td>
                <td>{value[2]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
}

export default SentenceResults