import React, { useState, useRef, useEffect,useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "../assets/styles/SentenceTest.module.css";
import styles1 from "../assets/styles/StoryTest.module.css";
import style2 from '../assets/styles/Questions.module.css'
import Cookies from "js-cookie";
import AuthContext from "../context/AuthContext";
import Story from "../components/Story";
import Questions from "../components/Questions";
const StoryTest = () => {
    const params = useParams();
    const test_type = params.test_type;
    const [number, setNumber] = useState(-1);
    const story = useRef("");
    let level = params.level;
    const ans = useRef();
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const childRef = useRef([]);
    const [showResults, SetShowResults] = useState(false);
    const [showStory, setShowStory] = useState(true)
    const showQuestions = useRef(false)
    const [questionNumber,setQuestionNumber] = useState(-1)
    const questionAnswer = useRef([])
    const [storyScore, setStoryScore] = useState(-1)

    useEffect(() => {
      if(level === '-1'){
        navigate(`/storyTest/${test_type}/${0}/${user}/`)
        window.location.reload(false); 
      }
      document.title = "Story Test";
    }, []);

    const getStory = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `JWT ${localStorage.getItem("access")}`,
          "x-csrftoken": Cookies.get("csrftoken"),
        },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getstory/${test_type}/${level}/`,
          config
        );
        story.current = response.data;
        setNumber(number + 1);
      } catch (err) {
        console.log(err);
      }
    };

    function getAnswer(text) {
      if(text === null || text === undefined || text === '') {
        ans.current = "null"
      }else{
      ans.current = text;}
    }
    function getQuestionAnswer(index, text) {
      if (text === null || text === undefined || text === '') {
        questionAnswer.current[index] = "null";
      } else {
        questionAnswer.current[index] = text;
      }
    }
    function submitHandler(){
        childRef.current.reset();
        setNumber(number + 1);
        if(test_type !== "1"){
            setShowStory(false)
        }
        showQuestions.current =true
        setQuestionNumber(questionNumber+1)
    }

    const postScore = async ()=>{
      setNumber(number + 1)
        const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `JWT ${localStorage.getItem("access")}`,
          "x-csrftoken": Cookies.get("csrftoken"),
        },
      };
      const body = JSON.stringify({
        user: user,
        level: level,
        test_type: test_type,
        story: story.current[1].story,
        spoken_story: ans.current,
        questions_list: story.current[2].toString(),
        answers_list: questionAnswer.current.toString(),
      });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/story/results/`,
          body,
          config
        );
        if(response.status === 201){
            if(level === '7' && test_type === '1'){
                navigate(`/multipleinventorytest/${user}`)
            }
            else if(level === '7' && test_type ==='2'){
                navigate(`/student_dashboard/${user}`);
            }
            else{
              let url = `/storyTest/${test_type}/${parseInt(level) + 1}/${user}/`;
                navigate(url)
            }
           window.location.reload(false); 
        }
      } catch (err) {
        console.log(err);
      }
    }

    function normalSubmit(){
        childRef.current.reset();
        setQuestionNumber(questionNumber + 1)
        showQuestions.current = true
    }




  return (
    <div className={styles.testContainer}>
      <h1 className={styles.testType}>
        {test_type === "1" ? "PRE TEST" : "POST TEST"}
      </h1>
      <h2 className={styles.testTitle}>ORAL READING COMPREHENSION</h2>
      <div className={styles.instructionbox}>
        <h2>Instructions</h2>
        <ul>
          <li>Click on START TEST button only once and wait.</li>
          <li>When test starts, Click on START button to and then speak</li>
          <li>First Click on STOP button then submit the test.</li>
          <li>After Submission, questions related to Story will be shown.</li>
        </ul>
      </div>
      {number === -1 ? (
        <div>
          <button
            className={styles.largebutton}
            onClick={() => {
              getStory();
            }}
          >
            START TEST
          </button>
        </div>
      ) : null}
      {number >= 0 ? (
        <div>
          {showStory ? (
            <div>
              <Story
                title={story.current[1].title}
                text={story.current[1].html_story}
                answer={getAnswer}
                ref={childRef}
              />
              {!showQuestions.current ? (
                <button
                  className={styles.largebutton}
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  SUBMIT
                </button>
              ) : null}
            </div>
          ) : null}
          {showQuestions.current &&
          questionNumber < story.current[2].length - 1 ? (
            <div className={style2.questionsContainer}>
              <h1>Questions</h1>
              <Questions
                index={questionNumber}
                text={story.current[2][questionNumber]}
                answer={getQuestionAnswer}
                ref={childRef}
              />
              <button
                className={styles.largebutton}
                onClick={() => {
                  normalSubmit();
                }}
              >
                SUBMIT
              </button>
            </div>
          ) : null}
          {showQuestions.current &&
          questionNumber === story.current[2].length - 1 ? (
            <div className={style2.questionsContainer}>
              <h1>Questions</h1>
              <Questions
                index={questionNumber}
                text={story.current[2][questionNumber]}
                answer={getQuestionAnswer}
                ref={childRef}
              />
              <button
                className={styles.largebutton}
                onClick={() => {
                  childRef.current.reset();
                  postScore();
                }}
              >
                SUBMIT TEST
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default StoryTest