import React, {useEffect, useState} from "react";
import styles from "../assets/styles/Home.module.css";
import {useNavigate} from 'react-router-dom'

export default function Home() {
  const navigate = useNavigate()
    useEffect(() => {
      document.title = "Peruzor";
    }, []);
      return (
        <div className={styles.parent}>
          <div className={styles.body}>
            <p className={styles.welcome}>Welcome to</p>
            <p className={styles.title}>PERUZOR</p>
            <p className={styles.headline}>Where Trasformation Meets Reading</p>
            <button className={styles.getstart} onClick={()=>{navigate('/login')}}>GET STARTED</button>
          </div>
        </div>
      ); 
  }


