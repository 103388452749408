import React, {useState,useRef, useEffect, useContext} from 'react'
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "../assets/styles/SentenceTest.module.css";
import Sentence from "../components/Sentence";
import AuthContext from "../context/AuthContext";


const Sentences = () => {
    const params = useParams()
    const test_type = params.test_type
    const [number, setNumber] = useState(-1)
    const sentences = useRef('')
    const ans = useRef([])
    const [score,setScore] = useState()
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const childRef = useRef([]);
    useEffect(() => {
      document.title = "Sentences Filter Test";
    }, []);

    const  getSentences = async ()=>{
                const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${localStorage.getItem("access")}`,
            "x-csrftoken": Cookies.get("csrftoken"),
          },
        };
        try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getsentences/`,
        config
      );
      if(response.statusCode === 201){
        setNumber(number+1)
      }
        
        }
        catch(err){
            console.log(err)
        }
    }

    function getAnswer(index, text){
      if(text == ans.current[index-1] || text == null || text==undefined){
        ans.current[index] = " "
      }
      else{ans.current[index] = text}
    }

    const postRestults = async()=>{
          const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${localStorage.getItem("access")}`,
            "x-csrftoken": Cookies.get("csrftoken"),
          },
        };
        const body = JSON.stringify({user:user,sentence1:ans.current[0]
                                              ,sentence2:ans.current[1]
                                              ,sentence3:ans.current[2]
                                              ,sentence4:ans.current[3]
                                              ,sentence5:ans.current[4]})
        try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sentences/results/`,
        body,
        config
      );
        if(response.status === 201){
          setScore(response.data.score)
        }
        }
        catch(err){
            console.log(err)
        }
    }

    function nextLevel(){
        let level = 0
        if(score[0] ===0){
            level = 0
        }
        else if (score[0] <90 && score[0] >0) {
            level = 0
        }
        else if (
          (score[0] > 90 && score[1] < 90) ||
          score[1] <90 ||
          score[2] <90 ||
          score[3] <90 ||
          score[4] <90 
        ) {
          level = 1;
        } else if (
          score[0] > 90 &&
          score[1] > 90 &&
          (score[2] < 90 || score[3] < 90 || score[4] < 90)
        ) {
          level = 2;
        } else if (
          score[0] > 90 &&
          score[1] > 90 &&
          score[2] > 90 &&
          (score[3] < 90 || score[4] < 90)
        ) {
          level = 3;
        } else if (
          score[0] > 90 &&
          score[1] > 90 &&
          score[2] > 90 &&
          score[3] > 90 &&
          score[4] < 90
        ) {
          level = 4;
        } else if (
          score[0] > 90 &&
          score[1] > 90 &&
          score[2] > 90 &&
          score[3] > 90 &&
          score[4] > 90
        ) {
          level = 5;
        } else {
          level = 2;
        }

        navigate(`/wordstest/${test_type}/${level}/${user}/`)
    }


  return (
    <div className={styles.testContainer}>
      <h1 className={styles.testType}>
        {test_type == "1" ? "PRE TEST" : "POST TEST"}
      </h1>
      <h2 className={styles.testTitle}>SENTENCES</h2>
      <div className={styles.instructionbox}>
        <h2>Instructions</h2>
        <ul>
          <li>Click on START TEST button only once and wait.</li>
          <li>When test starts, Click on START button to and then speak</li>
          <li>First Click on STOP button then submit the test.</li>
          <li>After Submission, next Sentence will be shown.</li>
          <li><b>Start Speaking from start of sentence every time you click STOP</b></li>
        </ul>
      </div>
      {number === -1 ? (
        <div>
          <button
            className={styles.largebutton}
            onClick={() => {
              getSentences();
            }}
          >
            START TEST
          </button>
        </div>
      ) : null}
      {number < 4 && number > -1 ? (
        <div>
          <Sentence
            index={number}
            text={sentences.current[number]}
            answer={getAnswer}
            ref={childRef}
          />
          <button
            className={styles.largebutton}
            onClick={() => {
              childRef.current.reset();
              setNumber(number + 1);
            }}
          >
            SUBMIT
          </button>
        </div>
      ) : null}
      {number === 4 ? (
        <div>
          <Sentence
            index={number}
            text={sentences.current[number]}
            answer={getAnswer}
            ref={childRef}
          />
          <button
            className={styles.largebutton}
            onClick={() => {
              childRef.current.reset();
              setNumber(number + 1);
              postRestults();
            }}
          >
            SUBMIT TEST
          </button>
        </div>
      ) : null}
      {number === 5 ? (
        <div>
          <h1 className={styles.score}>Sentences Test Completed!</h1>
          <button
            onClick={() => {
              nextLevel();
            }}
            className={styles.largebutton}
          >
            NEXT
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Sentences