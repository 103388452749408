import React, {useEffect} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import styles from '../assets/styles/StudentDashboard.module.css'
import pretest from '../assets/images/pretest.png'
import multipleintelligence from "../assets/images/multipleintelligence.png";
import interestinventory from "../assets/images/interestinventory.png";
import intervention from "../assets/images/intervention.png";
import posttest from "../assets/images/posttest.png";



const StudentDashboard = () => {
  const params = useParams()
  const navigate = useNavigate()
  const user_id = params.user_id
  useEffect(() => {
    document.title = "Student Dashboard";
  }, []);


  return (
    <div className={styles.studentDashboard}>
      {/* <h3>Click on test to START!</h3> */}
      <div className={styles.imageDiv} onClick={()=>{navigate("/lettertest/1");}}>
        <img src={pretest} alt="PRETEST" className={styles.testImage} />
      </div>
      <div className={styles.imageDiv} onClick={()=>{navigate(`/multipleinventorytest/${user_id}`);}}>
        <img
          src={multipleintelligence}
          alt="Multiple Intelligence Test"
          className={styles.testImage}
        />
      </div>
      <div className={styles.imageDiv} onClick={()=>{navigate(`/interestinventory/${user_id}`);}}>
        <img
          src={interestinventory}
          alt="Interest Inventory Test"
          className={styles.testImage}
        />
      </div>
      <div className={styles.imageDiv} onClick={() =>{navigate('/intervention')}}>
        <img
          src={intervention}
          alt="Intervention(Coming Soon)"
          className={styles.testImage}
        />
      </div>
      <div className={styles.imageDiv} onClick={() =>{navigate('/lettertest/2')}}>
        <img
          src={posttest}
          alt="POSTTEST"
          className={styles.testImage}
        />
      </div>
    </div>
  );
}

export default StudentDashboard