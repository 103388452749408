import React ,{useState, useEffect, useContext} from "react";
import {useNavigate} from 'react-router-dom'
import styles from '../assets/styles/Intro.module.css'
import AuthContext from "../context/AuthContext";
import {useSpeechSynthesis} from 'react-speech-kit'
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from 'axios'
import Cookies from "js-cookie";
const Intro = () => {
    const [number, setNumber] = useState(0);
    const [name, setName] = useState('')
    const [country, setCountry] = useState('')
    const {speak,  voices } = useSpeechSynthesis();
    const { listening, transcript, resetTranscript } = useSpeechRecognition();
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

      useEffect(() => {
        document.title = "Introduction";
      }, []);
    const startTest = async()=>{

      let test_type = "1";
       const config = {
         headers: {
           "Content-Type": "application/json",
           "Authorization": `JWT ${localStorage.getItem("access")}`,
           "x-csrftoken": Cookies.get("csrftoken"),
         },
       };
    const body = JSON.stringify({user:user,done:true})
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/intro-test-completed/`,
        body,
        config
      );
       
    } catch (err) {
      console.log(err);
    }
      navigate(`/lettertest/${test_type}/`);
    }

  return (
    <div className ={styles.introContainer}>
      {number < 1 ? (
        <button
          className={styles.largebutton}
          onClick={() => {
            setNumber(number + 1);
            speak({
              text: "Hi! I'm Alex, What's your name?",
              voice: voices[1],
            });
          }}
        >
          CLICK TO START
        </button>
      ) : null}
      {number > 0 ? (
        <div className={styles.questionContainer}>
          <h1 className={styles.question}>Hi! I'm Alex, What's your name?</h1>
          <div className={styles.btnContainer}>
            <button
              className={styles.introbutton}
              disabled={listening ? true : false}
              onClick={() => {
                SpeechRecognition.startListening({ continuous: true });
              }}
            >
              START
            </button>
            <button
              className={styles.introbutton}
              disabled={listening ? false : true}
              onClick={SpeechRecognition.stopListening}
            >
              STOP
            </button>
            <button
              className={styles.introbutton}
              disabled={transcript ? false : true}
              onClick={resetTranscript}
            >
              RESET
            </button>
            {name ? <p>Name set to: {name}</p> : null}
          </div>
          <button
            className={styles.submitButton}
            onClick={() => {
              setNumber(number + 1);
              SpeechRecognition.stopListening();
              setName(transcript);
              resetTranscript();
              speak({
                text: "I'm from Jamaica, Where are you from?",
                voice: voices[1],
              });
            }}
            disabled={transcript || listening ? false : true}
          >
            SUBMIT
          </button>
        </div>
      ) : null}
      {number > 1 ? (
        <div className={styles.questionContainer}>
          <h1 className={styles.question}>
            Hi {name}! I'm from Jamaica, Where are you from?
          </h1>
          <div className={styles.btnContainer}>
            <button
              className={styles.introbutton}
              disabled={listening ? true : false}
              onClick={() => {
                SpeechRecognition.startListening({ continuous: true });
              }}
            >
              START
            </button>
            <button
              className={styles.introbutton}
              disabled={listening ? false : true}
              onClick={SpeechRecognition.stopListening}
            >
              STOP
            </button>
            <button
              className={styles.introbutton}
              disabled={transcript ? false : true}
              onClick={resetTranscript}
            >
              RESET
            </button>
          </div>
          <p>{transcript ? transcript : country}</p>
          {country ? <p>Country set to: {country}</p> : null}
          <button
            className={styles.submitButton}
            onClick={() => {
              setNumber(number + 1);
              SpeechRecognition.stopListening();
              setCountry(transcript);
              resetTranscript();
              speak({
                text: "You will be doing a Reading Assessment. That includes;identifying letter names and sounds, identifying sight words,reading one or more stories and then answering some questions. This is not a pass or fail kind of test so relax and do your best. Thank you.",
                voice: voices[1],
              });
            }}
            disabled={transcript || listening ? false : true}
          >
            SUBMIT
          </button>
        </div>
      ) : null}
      {number > 2 ? (
        <div className={styles.questionContainer}>
          <p>
            {name}, You will be doing a Reading Assessment. That includes;
            identifying letter names and sounds, identifying sight words,
            reading one or more stories and then answering some questions. This
            is not a pass or fail kind of test so relax and do your best. Thank
            you.
          </p>
          <button
            className={styles.introbutton}
            onClick={() => {
              startTest()
            }}
          >
            START TEST
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Intro;
